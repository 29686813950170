// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@import "node_modules/bootstrap/scss/bootstrap.scss";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import "~select2/dist/css/select2.min.css";

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
.login-form{
    margin-top: 5% ;
}

